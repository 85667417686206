import {Box} from 'components/mui';
import React from 'react';
import {Logger} from 'utils/logger';

const logger = new Logger('error-boundary');

const ERROR_MESSAGE = 'An error has occurred. Please try reloading the page.';

type ErrorBoundaryProps = {
    children: React.ReactNode;
};

type ErrorBoundaryState = {
    error: Error;
};

const initialState: ErrorBoundaryState = {
    error: null,
};

class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = initialState;
    }

    static getDerivedStateFromError(error: Error) {
        return {error};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logger.error(
            `Error boundary caught error` +
                `: ${error.name}` +
                `: ${error.message}` +
                (error.stack ? `: ${error.stack}` : '') +
                `: ${errorInfo.componentStack}`
        );
    }

    render() {
        const {error} = this.state;

        if (error) {
            return <Box p={2}>{ERROR_MESSAGE}</Box>;
        }

        return this.props.children;
    }
}

export {ErrorBoundary, ERROR_MESSAGE};
