import useAllExegraphicLenses from 'components/hooks/useAllExegraphicLenses';
import {PageLoadingSpinner} from 'components/ui/graphics/PageLoadingSpinner';
import {ExegraphicLensesContext} from 'components/contexts/exegraphicLenses';

interface ExegraphicLensesProviderProps {
    children: React.ReactNode;
}

export default function ExegraphicLensesProvider({
    children,
}: ExegraphicLensesProviderProps) {
    const {allExegraphicLensNames, loading: areAllExegraphicLensesLoading} =
        useAllExegraphicLenses();

    if (areAllExegraphicLensesLoading) {
        return <PageLoadingSpinner />;
    }

    return (
        <ExegraphicLensesContext.Provider value={allExegraphicLensNames}>
            {children}
        </ExegraphicLensesContext.Provider>
    );
}
