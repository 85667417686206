import React, {Component, Fragment} from 'react';
import {Box} from 'components/mui';
import PropTypes from 'prop-types';
import {MenuItemsGroup} from 'components/ui/menu/MenuItemWrapper';
import {MobileNavigation} from 'components/ui/navigation/MobileNavigation.tsx';
import {mainMobileNavItems} from './menu.config';

const TIME_TO_ALLOW_DROPDOWN_TO_CLOSE = 200;

const mobileNavItems = mainMobileNavItems;

class DefaultMobileMenu extends Component {
    constructor() {
        super();

        this.state = {
            isDropdownMenuOpen: false,
            isDropdownClosing: false,
        };

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
        this.toggleDropdownMenuOpen = this.toggleDropdownMenuOpen.bind(this);
        this.preventDropdownFromOpening =
            this.preventDropdownFromOpening.bind(this);
        this.menuCloseTimeout = null;
    }

    showDropdownMenu() {
        if (!this.state.isDropdownClosing) {
            this.setState({isDropdownMenuOpen: true});
        }
    }

    hideDropdownMenu() {
        this.setState(
            {isDropdownMenuOpen: false, isDropdownClosing: true},
            (params) => {
                this.menuCloseTimeout = setTimeout(
                    this.preventDropdownFromOpening,
                    TIME_TO_ALLOW_DROPDOWN_TO_CLOSE
                );
            }
        );
    }

    preventDropdownFromOpening() {
        this.setState({isDropdownClosing: false});
    }

    isFocusAChildOfTarget(event) {
        if (!event || !event.relatedTarget) {
            return false;
        }

        const {relatedTarget, currentTarget} = event;
        let parentNode = relatedTarget.parentNode || null;

        while (parentNode !== null) {
            if (parentNode === currentTarget) {
                return true;
            }

            parentNode = parentNode.parentNode;
        }

        return false;
    }

    toggleDropdownMenuOpen(e) {
        const {isDropdownMenuOpen} = this.state;

        e.preventDefault();

        if (isDropdownMenuOpen) {
            this.hideDropdownMenu(e);
        } else {
            this.showDropdownMenu(e);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.menuCloseTimeout);
        this.menuCloseTimeout = null;
    }

    render() {
        const {activeNavItem} = this.props;

        return (
            <Fragment>
                <MenuItemsGroup activeNavItem={activeNavItem}>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-around"
                        bgcolor="primary.black"
                    >
                        <MobileNavigation
                            navItems={mobileNavItems}
                            activeNavItem={activeNavItem}
                        />
                    </Box>
                </MenuItemsGroup>
            </Fragment>
        );
    }
}

DefaultMobileMenu.propTypes = {
    activeNavItem: PropTypes.string.isRequired,
};

export {DefaultMobileMenu};
