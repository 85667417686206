import {Box, CircularProgress} from 'components/mui';

const PageLoadingSpinner = () => (
    <Box
        style={{width: '100%'}}
        display="flex"
        justifyContent="center"
        data-testid="page-loading-spinner"
        mt={12}
    >
        <CircularProgress size={50} />
    </Box>
);

export {PageLoadingSpinner};
