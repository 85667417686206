import {styled} from '@mui/material/styles';
import {Container, Box} from 'components/mui';
import {NotificationsContext} from 'components/contexts/notifications';
import NotificationMessages from 'components/ui/notification/NotificationMessages';

import {DefaultMobileMenu} from 'components/ui/layouts/menus/DefaultMobileMenu';

const MobilePageBox = styled(Box)({
    position: 'absolute' as const,
    width: '100%',
    minHeight: 'calc(100% - 45px)',
    backgroundColor: '#eef2ee',
    '.modal-open &': {
        paddingRight: '15px',
    },
    '& .mobile-content': {
        width: '100%',
    },
});

const DefaultMobileLayout = ({
    pageId,
    children,
}: {
    pageId: string;
    children: React.ReactNode;
}) => {
    return (
        <Container maxWidth={false} disableGutters>
            <Box>
                <DefaultMobileMenu activeNavItem={pageId} />
            </Box>
            <MobilePageBox>
                <Box className="mobile-content" data-testid={`page-${pageId}`}>
                    {children}
                </Box>
            </MobilePageBox>
            <NotificationsContext.Consumer>
                {(notificationMessages) => {
                    if (
                        !notificationMessages ||
                        notificationMessages.length === 0
                    )
                        return null;
                    return (
                        <NotificationMessages messages={notificationMessages} />
                    );
                }}
            </NotificationsContext.Consumer>
        </Container>
    );
};

export {DefaultMobileLayout};
