import {useQuery} from 'graphql/client';
import ALL_LENSES from 'graphql/pages/aicp/exegraphicLenses.graphql';
import {ExegraphicLensLabels} from 'core/ExegraphicLens/exegraphicLensLabels';

const useAllExegraphicLenses = () => {
    const {data, loading} = useQuery(ALL_LENSES, {});

    const allLenses = data?.exegraphicLenses?.lenses ?? [];

    const allExegraphicLensNames: ExegraphicLensLabels = {};

    for (const lens of allLenses) {
        allExegraphicLensNames[lens.name] = {
            displayName: lens.displayName,
            description: lens.description,
            id: lens.id,
        };
    }

    return {
        allExegraphicLensNames,
        loading,
    };
};

export default useAllExegraphicLenses;
