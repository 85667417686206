import {Box} from 'components/mui';
import {NavMenuItem} from 'components/ui/menu/MenuItem';

type NavigationProps = {
    activeNavItem: string;
    navItems: {
        id: string;
        href: string;
        label?: string;
        featureFlag?: string;
        tooltipPlacement?: string;
        onClick?: () => void;
    }[];
    navigationExpanded?: boolean;
};

const MobileNavigation = ({activeNavItem, navItems}: NavigationProps) => {
    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                gap={2}
                flexWrap="wrap"
                justifyContent="center"
            >
                {navItems.map((navItem) => {
                    return (
                        <Box key={navItem.id}>
                            <NavMenuItem
                                menuItemId={navItem.id}
                                href={navItem.href}
                                activeNavItem={activeNavItem}
                                onClick={navItem.onClick}
                            />
                        </Box>
                    );
                })}
            </Box>
        </>
    );
};

export {MobileNavigation};
